<template>
    <div class="body">
        <div class="concol contentbody">
            <div class="head" v-if="!detailsFlag&&content.value">
                 <div v-html="content.value.en_content"></div>
            </div>
            <div class="title">Reviewing Information</div>
            <el-form :model="ruleForm" ref="ruleFormRef" status-icon label-width="200px" class="ruleForm">
                <el-form-item label="Score：" prop="score"
                :rules="[{
                  required: detailsFlag?false:true,
                  message: 'Please make sure your reviewing information is complete',
                  trigger: 'change',
                }]">
                    <el-rate :disabled='detailsFlag'  v-model="ruleForm.score" size="large" 
                       show-score :max='10' :allow-half='true'/> 
                </el-form-item>
                <el-form-item v-if="!detailsFlag" label="Comments：" prop="comment" value="评语" 
                :rules="[{
                  required: true,
                  message: 'Please enter a comment',
                  trigger: 'blur',
                }]">
                    <el-input v-model="ruleForm.comment" :rows="5" type="textarea" autocomplete="off"  show-word-limit />
                </el-form-item>
                <el-form-item v-else label="Comments：" prop="" value="评语">
                    {{ruleForm.comment}}
                </el-form-item>
                <el-form-item class="btn" v-if="!detailsFlag">
                    <el-button @click="router.push('/review-index?index=2')">Back</el-button>
                    <el-button @click="submitForm(ruleFormRef)">Save</el-button>
                </el-form-item>
            </el-form>
            <div class="title">Applicant Information</div>
            <el-form class="form info_form" label-width="318px" v-if="formInfo.value">
                <el-row :gutter="10">
                    <el-col :span="12">
                        <el-form-item label="Chinese name：">{{formInfo.value.cn_name}}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="English name：">{{formInfo.value.en_name}}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Gender：">
                            <!-- {{formInfo.value.gender===1?'female':'male'}} -->
                            <el-radio-group v-model="formInfo.value.gender" disabled>
                                <el-radio :label="2">female</el-radio>
                                <el-radio :label="1">male</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Highest level of education：">{{formInfo.value.highest_education_name_en}}</el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="Personal homepage or Lab homepage： ">
                            <a :href="formInfo.value.home_page" target="_blank">{{formInfo.value.home_page}}</a>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="University/Institution/Company：">{{formInfo.value.company}}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Position：">{{formInfo.value.position}}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Research field：">{{formInfo.value.subject_name_en}}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Research interest：">{{formInfo.value.research_direction}} </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="Research results & Innovative projects：">
                   {{formInfo.value.research_results}}
                </el-form-item>
            </el-form>
            <div class="title">Attachment <button v-if="!detailsFlag" @click="downMaterials" v-loading='down_load'>Download all the attachments</button></div>
            <el-form class="form" label-width="265px" label-position="right">
                <el-row :gutter="10">
                    <template v-if="formInfo.value">
                    <el-col :span="12">
                        <el-form-item label="Curriculum Vitae（Chinese）：">
                            <a :href="formInfo.value.cn_resume" target="_blank">{{formInfo.value.cn_name}}_中文简历.pdf</a>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Curriculum Vitae（English）："><a :href="formInfo.value.en_resume" target="_blank">{{formInfo.value.en_name}}__CV.pdf</a></el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Personal Statement（Chinese）："> <a :href="formInfo.value.cn_personal_statement" target="_blank">{{formInfo.value.cn_name}}_个人陈述.pdf</a></el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Personal Statement（English）："><a :href="formInfo.value.en_personal_statement" target="_blank">{{formInfo.value.en_name}}_Personal Statement.pdf</a></el-form-item>
                    </el-col>
                    </template>
                    <template v-if="recommendInfo.value&&formInfo.value">
                        <el-col :span="12">
                            <el-form-item label="Reference letter（Chinese）：">
                                <template  v-for="item in recommendInfo.value" :key="item.id">
                                     <div style="margin-bottom: 20px" v-if="item.cn_reference_letter">
                                        <a :href="item.cn_reference_letter"  target="_blank">
                                            {{formInfo.value.cn_name}}_推荐信_{{item.cn_name||item.en_name}}.pdf
                                        </a>
                                    </div>
                                </template>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="Reference letter（English）：">
                                  <template  v-for="item in recommendInfo.value" :key="item.id">
                                     <div style="margin-bottom: 20px" v-if="item.en_reference_letter" >
                                        <a :href="item.en_reference_letter" target="_blank">
                                        Reference letter for {{formInfo.value.en_name}}_{{item.en_name}}.pdf
                                    </a>
                                    </div>
                                </template>
                            </el-form-item>
                        </el-col>
                    </template>
                    <template v-if="otherInfo.value&&formInfo.value">
                        <el-col :span="12">
                            <el-form-item label="Supplementary Info：">
                                <div v-for="(item,i) in otherInfo.value.additional_judge_information" :key="i">
                                    <a :href="item.url" target="_blank">{{formInfo.value.en_name}}_Additional information_{{item.name}}</a>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="About Innovation：">
                                <a :href="otherInfo.value.about_innovation" target="_blank">{{formInfo.value.en_name}}_Questions about innovation.pdf</a>
                            </el-form-item>
                        </el-col>
                    </template>
                </el-row>
            </el-form>
            <div class="back">
                  <el-button size="small" v-if="detailsFlag" @click="router.push('/review-index?index=2')">back</el-button>
            </div>
        </div>
    </div>
</template>
<script>
    import { defineComponent, ref, computed, watch, reactive } from "vue";
    import { useGetters } from "@/store/use.js";
    import { useRouter, onBeforeRouteUpdate } from "vue-router";
    import store from "@/store";
    import { ElMessageBox, ElMessage,ElForm } from 'element-plus'
    import { Ajax } from "@/utils";
    export default defineComponent({
        name: "查看详情",
        setup() {
            const router = useRouter();
            const query = router.currentRoute.value.query;
            const detailsFlag=ref(query.flag),down_load=ref(false);
            const content=reactive({}),recommendInfo=reactive([]),otherInfo=reactive({})
             store.dispatch('app/getJudges_rules').then(res=>{
                content.value=res.evalaute_notice;
            });
             //获取个人信息
            const formInfo=reactive({})
            const get_reviewInfo=()=>{
                Ajax.POST({url: "/api/getPersonalInformation",data:{apply_id:query.apply_id}}).then((res) => {
                    if (res.data.code === 10000) {
                        formInfo.value=res.data.data;
                    }
                });
                Ajax.POST({url: "/api/getRecommendInformation",data:{apply_id:query.apply_id}}).then((res) => {
                    if (res.data.code === 10000) {
                        recommendInfo.value=res.data.data;
                    }
                });
                Ajax.POST({url: "/api/getOtherInformation",data:{apply_id:query.apply_id}}).then((res) => {
                    if (res.data.code === 10000) {
                        otherInfo.value=res.data.data;
                    }
                });
                Ajax.POST({url: "/api/judges/reviewInfo",data:{id:query.id}}).then((res) => {
                    if (res.data.code === 10000) {
                        let data=res.data.data;
                        ruleForm.score=data.score;
                        ruleForm.comment=data.comment;
                        console.log(ruleForm)
                    }
                });
            }
            get_reviewInfo()
            const ruleForm = reactive({
                id:query.id,
                score: '',
                comment: '',
            })
            //下载全部附件
            const downMaterials=()=>{
                down_load.value=true
                 Ajax.POST({url: "/api/judges/downMaterials",data:{id:query.id}}).then((res) => {
                     down_load.value=false
                    if (res.data.code === 10000) {
                       window.location.href=res.data.data.down_url
                    }
                });
            }
            const ruleFormRef = ref();
            const submitForm=(formEl)=>{
                if(ruleForm.score===0) {
                     ElMessage({
                        message: 'Please make sure your reviewing information is complete.',
                        type: 'error',
                    })
                    return false;
                }
                if (!formEl) return;
                formEl.validate((valid) => {
                    if (valid) {
                    //    ElMessageBox.confirm('Your reviewing information cannot be changed after submission. Please confirm your submission.', 'Tips', {
                    //         confirmButtonText:'Confirm',
                    //         cancelButtonText:'Cancel',
                    //         type: 'warning'
                    //     }).then(() => {
                           Ajax.POST({url: "/api/judges/scoreComment",data:ruleForm}).then((res) => {
                                if (res.data.code === 10000) {
                                    ElMessage({
                                        message: 'Saved successfully',
                                        type: 'success',
                                    })
                                    // setTimeout(()=>{
                                    //     router.push('/review-index?index=2')
                                    // })
                                }
                            });
                        // }).catch(() => { return false})
                         
                    } else {
                    console.log("error submit!");
                    return false;
                    }
                });
            }
            return {
                router,
                formInfo,
                recommendInfo,
                otherInfo,
                ruleForm,
                ruleFormRef,
                content,
                detailsFlag,
                down_load,
                submitForm,
                downMaterials
            };
        },
    });
</script>
<style lang="scss" scoped>
    .body {
        background: #f6f6f6;
        width: 100%;
        font-family: PingFangSC-Regular, PingFang SC;

        .concol {
            padding: 17px 40px 40px;
            margin-left: 6px;
            background: #fff;
            min-height: calc(100vh - 359px);
            color: #333333;
            margin: 20px auto 50px;

            .head {
                width: 1120px;
                min-height: 128px;
                background: #FEF3D6;
                padding: 16px 20px;
                font-size: 16px;
                font-weight: 400;
                color: #333333;
                line-height: 24px;
                margin-bottom: 51px;
            }

            .title {
                height: 29px;
                font-size: 21px;
                font-weight: 600;
                color: #333333;
                line-height: 29px;
                padding-left: 8px;
                border-left: 8px solid #E34C3E;
                margin: 57px 44px 46px;
                button{
                    font-size: 14px;
                    border: 1px solid #E34C3E;
                    background: #E34C3E;
                    border-radius: 4px;
                    padding: 7px 20px;
                    height: 34px;
                    cursor: pointer;
                    // line-height: 25px;
                    margin-left: 60px;
                    color: #FFFFFF;
                }
            }

            :deep(.ruleForm) {
                .el-form-item:nth-child(1) {
                    margin-bottom: 51px;
                }
                .el-form-item__label {
                    height: 28px;
                    font-size: 20px;
                    font-weight: 600;
                    color: #262626;
                    line-height: 28px;
                }
                .el-rate__item {
                    width: 35px;
                    height: 34px;
                    margin-right: 15px;
                    .icon {
                        width: 35px;
                        height: 34px;
                    }
                    .el-rate__decimal{
                             width: 17.5px !important;
                            height: 34px !important;
                        }
                }
                .el-form-item__content {
                    min-height: 22px;
                    font-size: 16px;
                    font-weight: 400;
                    color: #333333;
                    line-height: 28px;
                    word-wrap: break-word;
                    word-break: normal;
                    .el-rate__text{
                        font-size: 20px;
                    }
                }
                .el-textarea__inner {
                    font-size: 16px;
                    font-weight: 400;
                    color: #333333;
                    line-height: 22px;
                }
            }
            :deep(.info_form){
                .el-form-item__content {
                    a{
                        color: #666666  !important;
                        text-decoration:none
                    }
                }
                 .el-radio{
                     height: 21px;
                        .el-radio__label{font-size: 16px;color: #333333;}
                        .el-radio__inner{
                             border-color: #DDD;
                             background: #fff;
                        }
                        .is-checked .el-radio__inner{
                            border-color: #E34C3E;
                            height: 16px;
                            width: 16px;
                        }
                        .el-radio__inner::after {
                                background-color: #E34C3E;
                                height: 8px;
                                width: 8px;
                            }
                    }
            }
            :deep(.form) {
                 font-family: PingFangSC-Regular, PingFang SC;
                .el-form-item__label {
                    font-size: 15px;
                    font-weight: 600;
                    color: #262626;
                    height: 30px;
                    line-height: 30px;
                }

                .el-form-item__content {
                    min-height: 22px;
                    font-size: 16px;
                    font-weight: 400;
                    color: #333333;
                    line-height: 30px;
                    word-wrap: break-word;
                    word-break: normal;
                    a{
                        color: #448DF7;
                        text-decoration:none
                    }
                }
            }
        }
        :deep(.back){
            text-align: center;
            button {
                font-size: 18px;
                border: 1px solid #E34C3E;
                color: #fff;
                background: #E34C3E;
                padding: 13px 39px;
                border-radius: 4px;
                width: 150px;
                height: 50px;
                margin-top: 20px;
                cursor: pointer;
                line-height: 25px;
                margin-right: 60px;
            }
        }
        :deep(.btn) {
            margin: 0px 0 22px;
            text-align: center;
            span {
                 font-size: 18px;
                font-weight: 400;
                color: #000000;
                line-height: 20px;
                margin-left: 10px;
            }
            button {
                font-size: 18px;
                border: 1px solid #BBBBBB;
                color: #000000;
                background: #fff;
                padding: 13px 39px;
                border-radius: 4px;
                width: 150px;
                height: 50px;
                margin-top: 20px;
                cursor: pointer;
                line-height: 25px;
                margin-right: 60px;
            }
            button:nth-child(2) {
                background: #E34C3E;
                border: 1px solid #E34C3E;
                color: #fff;
                span{
                     color: #fff;
                }
            }
        }
    }

    @media screen and (min-width: 375px) and (max-width: 750px) {
        .contentbody {
            width: 95%;

            .menucol {
                display: none;
            }

            .concol {
                max-width: 100%;
                flex: 100%
            }
        }
    }
</style>